export default {
  development: {
    // 开发环境接口请求
    // API: 'http://116.205.157.214:8127',
    // API: 'https://wallet.zhimadi.cn',
    API: 'https://wallet.guolianlian.com',
    // 开发环境 cdn 路径
    CDN: '',
  },
  test: {
    // 测试环境接口地址
    API: 'https://wallet.zhimadi.cn',
    // 测试环境 cdn 路径
    CDN: '',
  },
  release: {
    // 正式环境接口地址
    // API: 'https://wallet.zhimadi.cn',
    // 正式环境 cdn 路径
    CDN: '',
  },
};
