import Vue from 'vue';
import App from './App';
import { router } from './router';
import axiosInstance from './utils/request';


Vue.prototype.$request = axiosInstance;
Vue.prototype.is_wxpay = /MicroMessenger/.test(window.navigator.userAgent);
Vue.prototype.is_alipay = /AlipayClient/.test(window.navigator.userAgent);
Vue.prototype.is_android = /android/.test(navigator.userAgent.toLowerCase());
Vue.prototype.is_ios = /ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
