import axios from 'axios';
import proxy from '../config/host';
import {Toast} from 'vant'

const env =  'development';

const API_HOST =  proxy[env].API;

const CODE = {
    LOGIN_TIMEOUT: 1000,
    REQUEST_SUCCESS: 200,
    REQUEST_FOBID: 1001,
};
const instance = axios.create({
    baseURL: API_HOST,
    timeout: 30*1000,
    withCredentials: true
});

// eslint-disable-next-line
// @ts-ignore
// axios的retry ts类型有问题
instance.interceptors.retry = 3;

instance.interceptors.request.use((config) => config);

instance.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            const { data } = response;
            if (parseInt(data.code) !== CODE.REQUEST_SUCCESS) {
                Toast(data.message)
            }
            return data;
        }
    },
    (err) => {
        // eslint-disable-next-line no-debugger
        debugger
        const { config } = err;

        if (!config || !config.retry) return Promise.reject(err);

        config.retryCount = config.retryCount || 0;

        if (config.retryCount >= config.retry) {
            return Promise.reject(err);
        }

        config.retryCount += 1;

        const backoff = new Promise((resolve) => {
            setTimeout(() => {
                resolve({});
            }, config.retryDelay || 1);
        });

        return backoff.then(() => instance(config));
    },
);

export default instance;
