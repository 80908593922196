import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    name: 'home',
    component: () => import('./view/home'),
    meta: {
      title: ' ',
      keepAlive:true
    }
  },
  {
    name: 'pay',
    component: () => import('./view/pay'),
    meta: {
      title: '商户收银台'
    }
  },
  {
    name: 'result',
    component: () => import('./view/result'),
    meta: {
      title: '支付结果'
    }
  },
  {
    name: 'success',
    component: () => import('./view/success'),
    meta: {
      title: '支付结果'
    }
  },
  {
    name: 'download',
    component: () => import('./view/download'),
    meta: {
      title: '下载芝富通APP'
    }
  },
  {
    name: 'test',
    component: () => import('./view/test'),
    meta: {
      title: '下载芝富通APP'
    }
  },
  {
    name: 'bind',
    component: () => import('./view/bind'),
    meta: {
      title: '绑定收款码牌'
    }
  },
  {
    name: 'bind_success',
    component: () => import('./view/bind/success'),
    meta: {
      title: '绑定收款码牌'
    }
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

// const router = new Router({
  // mode: 'history',routes });

const router = new Router({routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
